<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-row class="d-flex align-center flex-end mb-2">
          <!-- <b-form-group> -->
          <b-col sm="1" class="pr-0">
            <label for="search-query">Search:</label>
          </b-col>
          <b-col sm="3" class="pl-0">
            <b-form-input
              type="text"
              placeholder="search query"
              v-model="searchData"
            ></b-form-input>
          </b-col>
          <!-- </b-form-group> -->
        </b-row>
        <b-card>
          <b-card-header>
            <i class="icon-menu mr-1"></i>List of Banners
            <div class="card-header-actions"></div>
          </b-card-header>
          <b-card-body>
            <ul class="px-0 my-0 banner-list" style="list-style-type: none">
              <li>
                <div class="row">
                  <div class="col-2">Movie</div>
                  <div class="col-2">Country Name</div>
                  <div class="col-2">Image</div>
                  <div class="col-2">Target</div>
                  <div class="col-2">Status</div>
                  <div class="col-2">Edit</div>
                </div>
              </li>
            </ul>

            <ul class="px-0 mt-0 banner-list" style="list-style-type: none">
              <!-- v-sortable="{onEnd: reorder}" -->
              <draggable
                :list="bannerList"
                class="list-group"
                ghost-class="ghost"
              >
                <li v-for="(item, index) in bannerList" :key="index">
                  <div class="row">
                    <div class="col-2">{{ item.Title }}</div>
                    <div class="col-2"></div>
                    <div class="col-2">
                      <img
                        :src="item.BaseURL + item.Image"
                        class="img-fluid"
                        alt
                      />
                    </div>
                    <div class="col-2">{{ item.TargetUrl }}</div>
                    <div class="col-2">
                      <c-switch
                        class="mx-1"
                        color="primary"
                        @change.native="changeStatus(item.BannerID)"
                        v-model="item.BannerIsActive"
                        variant="3d"
                        label
                        v-bind="labelIcon"
                      />
                    </div>
                    <div class="col-2">
                      <i
                        class="fa fa-edit"
                        @click="
                          editBanner(
                            item.MovieID,
                            item.BannerID,
                            item.CountryID
                          )
                        "
                      ></i>
                    </div>
                  </div>
                </li>
              </draggable>
            </ul>
            <b-button
              type="submit"
              size="sm"
              @click="sortItems"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i> Sort
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import Sortable from "sortablejs";
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import draggable from "vuedraggable";

Vue.directive("sortable", {
  inserted: function (el, binding) {
    var sortable = new Sortable(el, binding.value || {});
  },
});
export default {
  data() {
    return {
      bannerList: [],
      searchData: "",
      // SetLabelIcon
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  components: {
    cSwitch,
    draggable,
  },
  computed: {},
  watch: {},
  beforeMount() {
    this.getBannersList();
  },
  mounted: function () {},
  methods: {
    getBannersList: function () {
      MasterService.getBannerList()
        .then((response) => {
          const { data } = response;
          if (data.Status && data.BannerList.length > 0) {
            this.bannerList = data.BannerList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          console.log("Catch on Banner-getBanners Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    sortItems: function () {
      // MasterService.sortBanner(this.items)
      MasterService.sortBanner(this.bannerList)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.showMessageSnackbar(data.Message);
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          console.log("Catch on Banner-getBanners Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Sort reodered function
    reorder: function ({ oldIndex, newIndex }) {
      const movedItem = this.items.splice(oldIndex, 1)[0];
      this.items.splice(newIndex, 0, movedItem);
      this.items.map((i, index) => {
        i.position = index + 1;
      });
    },
    editBanner: function (movieId, bannerId, countryId) {
      // if (movieId > 0) {
      if (movieId !== "0") {
        this.$router.push({
          path: "/banners/addbanner",
          query: {
            movieId,
            bannerId,
            countryId,
          },
        });
      } else if (movieId == "0") {
        this.$router.push({
          path: "/banners/addstaticbanner",
          query: {
            bannerId,
            countryId,
          },
        });
      }
    },
    changeStatus: function (ID) {
      let payload = {
        TableName: "BannerImage",
        ID,
      };
      MasterService.activateOrDeactivate(payload)
        .then((response) => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
  updated() {},
};
</script>

<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
.banner-list li {
  padding: 20px;
  list-style-type: none;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #eee;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.banner-list li .row .col-2,
.col-3 {
  text-align: center;
  /* border-right: 1px solid green;
  height: 40px; */
}
/* .banner-list li  */
</style>
